import * as React from "react";
import {useRouter} from "next/router";
import NextLink from "next/link";
import addHours from 'date-fns/addHours'
import format from 'date-fns/format'
import useMediaQuery from "@mui/material/useMediaQuery";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import CategoryCreate from "./CategoryCreate";
import IconFlaticon from "@/components/IconFlaticon";
import {getThemeColors} from "@/components/showcase/_helpers";
import {ButtonGray, ButtonTransparent, StyledInput} from "@/components/showcase/_controls";
import {motion} from "framer-motion";
import LoadingButton from "@mui/lab/LoadingButton";
import {IMaskInput} from "react-imask";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import clsx from "clsx";

const modalBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto'
}

const PhoneMask = React.forwardRef(
    function PhoneMask(props, ref) {
        const { onChange, ...other } = props;
        delete other.value
        return (
            <IMaskInput
                {...other}
                mask="$(#00)000-00-00"
                placeholder='8(000)000-00-00'
                definitions={{
                    '#': /[1-9]/,
                    '$': /[7-8]/,
                }}
                inputRef={ref}
                onAccept={(value) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    }
);

function ModalRequestCall(props){
    const device = props.device
    const mediaMax = useMediaQuery('(max-width:470px)')
    const [screenPhone, setScreenPhone] = React.useState(device === 'mobile');

    React.useEffect(()=>{
        //console.log(mediaMax, screenMobile)
        setScreenPhone(mediaMax)
    }, [mediaMax])

    function OrderForm(){
        return(
            <>
                {props.isRequestSent ? (
                    <div className={clsx(screenPhone ? 'bg-white rounded-t-xl' : 'pb-8 px-20')}>
                        {screenPhone && (
                            <div className='flex w-16 h-1 mx-auto my-4 bg-gray-3 rounded-xl'></div>
                        )}
                        <div className='flex flex-col space-y-5 px-3 pb-6 tablet:p-8 items-center'>
                            <img className='w-44 mt-8 mb-2' src='/assets/showcase/images/request-sent.svg' alt='IMAGE'/>
                            <div className='w-full font-bold text-center text-xl laptop:text-3xl leading-none'>Заявка отправлена!</div>
                            <div className='w-full pb-2 text-center text-sm laptop:text-lg'>В ближайшее время мы свяжемся с вами.</div>
                        </div>
                    </div>
                ) : (
                    <form className={clsx(screenPhone && 'bg-white rounded-t-xl', '')} noValidate onSubmit={props.onSubmit}>
                        {screenPhone && (
                            <div className='flex w-16 h-1 mx-auto my-4 bg-gray-3 rounded-xl'></div>
                        )}
                        <div className='flex flex-col space-y-5 px-3 pb-6 tablet:p-8'>
                            <div className='w-full font-bold text-center text-xl laptop:text-3xl leading-none'>Заявка на консультацию</div>
                            <div className='w-full pb-3 text-center text-sm laptop:text-lg'>Укажите имя и контактный номер телефона, наш представитель свяжется с вами в ближайшее время</div>
                            <StyledInput
                                title={'Ваше имя'}
                                // hint={<>Например: <span className='italic'>Иванов Иван Иванович</span></>}
                                name={'name'}
                                // defaultValue={user.name}
                                error={props.errors.name}
                                register={props.register}
                                hintMargins={'mt-1 mb-0'}
                                colorScheme={props.themeColor ? props.themeColor : 0}
                                required={true}
                            />
                            <StyledInput
                                title={'Номер телефона'}
                                // hint={<>Например: <span className='italic'>Иванов Иван Иванович</span></>}
                                name={'phone'}
                                // defaultValue={user.name}
                                error={props.errors.phone}
                                register={props.register}
                                inputComponent={PhoneMask}
                                hintMargins={'mt-1 mb-4'}
                                colorScheme={props.themeColor ? props.themeColor : 0}
                                required={true}
                            />
                            <LoadingButton
                                style={{backgroundColor:getThemeColors(props.themeColor ? props.themeColor : 0)}}
                                className={'w-full py-3 text-sm laptop:text-base text-white bg-gradient-to-r from-pink-1 hover:from-purple-1 to-purple-1 hover:to-purple-1 font-normal shadow-none hover:shadow-none normal-case rounded-2xl cursor-pointer'}
                                loading={props.onLoading}
                                variant="contained"
                                type="submit"
                            >
                                Отправить заявку
                            </LoadingButton>
                            <div className='px-5 text-center text-xs laptop:text-sm text-gray-14'>
                                Отправляя заявку вы соглашаетесь с <NextLink className='text-gray-14 underline' href={'/privacy'}>политикой обработки персональных данных</NextLink>
                            </div>
                        </div>
                    </form>
                )}
            </>

        )
    }

    return(
        <>
            {screenPhone ? (
                <React.Fragment key={props.anchor}>
                    <SwipeableDrawer
                        anchor={props.anchor}
                        open={props.isOpen}
                        onClose={props.onClose}
                        onOpen={props.onOpen}
                    >
                        <OrderForm/>
                    </SwipeableDrawer>
                </React.Fragment>
            ) : (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={props.isOpen}
                    onClose={props.onClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: 'rgba(0, 0, 0, 0.43)'
                            },
                            timeout: screenPhone ? 150 : 500
                        },
                    }}
                >
                    <Fade in={props.isOpen}>
                        <Box sx={{ ...modalBoxStyle}} className='w-full tablet:w-auto tablet:max-w-xl h-screen tablet:h-auto bg-white border-0 outline-0 rounded-0 tablet:rounded-3xl'>
                            <OrderForm/>
                        </Box>
                    </Fade>
                </Modal>
            )}
        </>
    )
}

function ModalCreateCategory(props){
    const screenMobile = useMediaQuery('(max-width:640px)');
    return(
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            onClose={props.close}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.43)'
                    },
                    timeout: screenMobile ? 150 : 500
                },
            }}
        >
            <Fade in={props.open}>
                <Box sx={{ ...modalBoxStyle}} className='z-30 w-full tablet:w-128 h-screen tablet:h-auto bg-white rounded-none tablet:rounded-24 border-0 outline-0'>
                    <div className='flex tablet:hidden flex-row h-16 w-full mt-8 pl-3 pr-4 border-0 border-b border-solid border-white-1'>
                        <div className='flex flex-col justify-center' onClick={props.close}>
                            <IconFlaticon style={{color: getThemeColors(props.themeColor)}} className={'flex text-3xl'} name={'rr-arrow-left'}/>
                        </div>
                    </div>
                    <CategoryCreate modal={true} parentCat={props.parentCat} onSetCat={props.onSetCat} />
                </Box>
            </Fade>
        </Modal>
    )
}

function ModalPayments({ user, shop }){
    if(!shop){
        return
    }
    if(!shop.shop_date_end){
        return
    }
    if(!user?.isLoggedIn){
        return
    }
    if(new Date(shop.shop_date_end).getTime() > new Date().getTime()){
        return
    }
    const router = useRouter()
    let type = getDiff(true)
    const date_blocked = format(addHours(new Date(shop.shop_date_end), 168), "dd.MM.yyyy")
    const date_delete = format(addHours(new Date(shop.shop_date_end), 2160), "dd.MM.yyyy")
    function stateFormRoute(){
        return router.asPath !== '/showcase/payments';
    }

    function getDiff(type = false){
        const diff = Math.abs(new Date().getTime() - new Date(shop.shop_date_end).getTime()) / 1000 / 3600 / 24
        if(diff >= 0 && diff <= 1){
            if(type){
                return 0
            }else{
                return stateFormRoute()
            }
        }else if(diff >= 3 && diff <= 4){
            if(type){
                return 1
            }else{
                return stateFormRoute()
            }
        }else if(diff >= 6 && diff <= 7){
            if(type){
                return 2
            }else{
                return stateFormRoute()
            }
        }else if(diff > 7){
            if(type){
                return 3
            }else{
                return stateFormRoute()
            }
        }else{
            if(type){
                return 0
            }else{
                return false
            }
        }
    }

    const [paymentMS, setPaymentMS] = React.useState(false);
    
    React.useEffect(()=>{
        setPaymentMS(getDiff())
    }, [])

    React.useEffect(()=>{
        if(type === 3){
            setPaymentMS(getDiff())
        }
    }, [router.asPath])

    function close(){
        if(type !== 3){
            setPaymentMS(false)
        }
    }

    const screenMobile = useMediaQuery('(max-width:640px)');
    const images = [
        '/assets/showcase/images/payments-icon-1.svg',
        '/assets/showcase/images/payments-icon-2.svg',
        '/assets/showcase/images/payments-icon-3.svg',
        '/assets/showcase/images/payments-icon-3.svg'];
    return(
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={paymentMS}
            onClose={close}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.43)'
                    },
                    timeout: screenMobile ? 150 : 500
                },
            }}
        >
            <Fade in={paymentMS}>
                <Box sx={{ ...modalBoxStyle}} className='z-30 w-full tablet:w-152 tablet:p-6 h-screen tablet:h-auto bg-white tablet:rounded-24 border-0 outline-0'>
                    <div className='flex tablet:hidden flex-row h-16 w-full mt-8 pl-3 pr-4 bg-white border-0 border-b border-solid border-white-1'>
                        <div className='flex flex-col justify-center' onClick={close}>
                            <IconFlaticon style={{color: getThemeColors(shop.themeColor)}} className={'flex text-3xl'} name={'rr-arrow-left'}/>
                        </div>
                    </div>
                    <div className='flex flex-col items-center space-y-8 py-5'>
                        <img className='w-36 mt-2' src={images[type ? type : 0]} alt='IMAGE'/>
                        {type === 0 && (
                            <div className='text-3xl font-black text-center'>
                                Внесите арендную плату
                            </div>
                        )}
                        {type === 0 && (
                            <div className='flex flex-col space-y-5'>
                                <div className='px-6 text-center leading-6'>
                                    Пожалуйста, оплатите аренду магазина до {date_blocked}. Если платеж не поступит за этот период, страница магазина будет скрыта. Если оплата не поступит до {date_delete}, информация магазина будет полностью удалена, а его адрес освободится.
                                </div>
                                <div className='px-6 text-center leading-6'>
                                    Пожалуйста, оплатите аренду до {date_blocked}, чтобы продолжить пользоваться магазином.
                                </div>
                            </div>
                        )}
                        {type === 1 && (
                            <div className='text-3xl font-black text-center'>
                                Через 3 дня магазин будет заблокирован
                            </div>
                        )}
                        {type === 1 && (
                            <div className='flex flex-col space-y-5'>
                                <div className='px-6 text-center leading-6'>
                                    Если вы хотите продолжать пользоваться магазином, пожалуйста, оплатите аренду в течение 72 часов. Если платеж за аренду не поступит в этот срок, магазин будет заблокирован.
                                </div>
                            </div>
                        )}
                        {type === 2 && (
                            <div className='text-3xl font-black text-center'>
                                Завтра магазин будет заблокирован
                            </div>
                        )}
                        {type === 2 && (
                            <div className='flex flex-col space-y-5'>
                                <div className='px-6 text-center leading-6'>
                                    Если вы хотите продолжать пользоваться магазином, пожалуйста, оплатите аренду в течение 24 часов. Если платеж за аренду не поступит в этот срок, магазин будет заблокирован.
                                </div>
                            </div>
                        )}
                        {type === 3 && (
                            <div className='text-3xl font-black text-center'>
                                Пожалуйста, оплатите аренду
                            </div>
                        )}
                        {type === 3 && (
                            <div className='flex flex-col space-y-5'>
                                <div className='px-6 text-center leading-6'>
                                    Работа с магазином будет доступна только после оплаты.
                                </div>
                                <div className='px-6 text-center leading-6'>
                                    Если оплата не поступит до {date_delete} информация вашего магазина будет полностью удалена.
                                </div>
                            </div>
                        )}
                        {type === 4 && (
                            <div className='px-6 text-center leading-6'>
                                Чтобы продолжить работать с магазином либо активировать его, оплатите аренду согласно условиям оферты. Если аренда не будет оплачена в течение 7 дней, магазин окончательно закроется, а его адрес освободится.
                            </div>
                        )}
                        {type === 5 && (
                            <div className='px-6 text-center leading-6'>
                                Кажется, вы забыли оплатить аренду магазина. Ничего страшного, на следующие 7 дней он останется активен, покупатели будут видеть товары и смогут отправлять заявки. Пожалуйста, оплатите аренду в ближайшее время, иначе через 7 дней страница магазина будет скрыта.
                            </div>
                        )}
                        {type === 6 && (
                            <div className='flex flex-col space-y-5'>
                                <div className='px-6 text-center leading-6'>
                                    К сожалению, вы не оплатили дальнейшее использование магазина, теперь он недоступен.
                                </div>
                                <div className='px-6 text-center leading-6'>
                                    Адрес магазина будет закреплен за вами на ближайшие __ месяцев, после чего магазин удаляется и адрес освобождается.
                                </div>
                                <div className='px-6 text-center leading-6'>
                                    Вы можете оплатить аренду в любое время этого периода, и магазин снова будет виден покупателям.
                                </div>
                            </div>
                        )}
                        {type === 7 && (
                            <div className='flex flex-col space-y-5'>
                                <div className='px-6 text-center leading-6'>
                                    К сожалению, вы не оплатили аренду, а значит работа с магазином больше не доступна. Если платеж за аренду не поступит в течение следующих 7 дней, магазин будет удален, а его адрес освобожден для другого арендатора.
                                </div>
                                <div className='px-6 text-center leading-6'>
                                    Пожалуйста, оплатите аренду в течение 7 дней с момента получения этого письма, и магазин снова заработает
                                </div>
                            </div>
                        )}
                        <ButtonTransparent href={`/showcase/payments`} component={NextLink} className='flex flex-row w-74 h-12 justify-center items-center text-white text-sm tablet:text-base bg-gradient-to-r from-pink-1 to-purple-1 hover:brightness-90 rounded-xl'>
                            Оплатить сейчас
                        </ButtonTransparent>
                        {type !== 3 && (
                            <div className='p-2 cursor-pointer' onClick={close}>
                                Оплачу позже
                            </div>
                        )}
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

function ModalOrder(props){
    const screenPhone = useMediaQuery('(max-width:470px)');

    function OrderForm(){
        return(
            <form className={clsx(screenPhone && 'bg-white rounded-t-xl', '')} noValidate onSubmit={props.onSubmit}>
                {screenPhone && (
                    <div className='flex w-16 h-1 mx-auto my-4 bg-gray-3 rounded-xl'></div>
                )}
                <div className='flex flex-col space-y-5 px-3 pb-6 tablet:p-8'>
                    <div className='hidden tablet:flex flex-row w-full justify-center tablet:justify-between'>
                        <div className='flex pb-3 font-black  text-xl laptop:text-3xl text-center laptop:text-left leading-none'>Данные для заказа</div>
                        <motion.div className={"flex items-center justify-center h-10 w-10 -mt-2.5 -mr-3.5 cursor-pointer"} whileTap={{ scale: 0.75 }} onClick={props.onClose}>
                            <IconFlaticon
                                name={"br-cross"}
                                className={"text-sm text-gray-9"}
                            />
                        </motion.div>
                    </div>
                    <StyledInput
                        title={'Ваше имя'}
                        // hint={<>Например: <span className='italic'>Иванов Иван Иванович</span></>}
                        name={'name'}
                        // defaultValue={user.name}
                        error={props.errors.name}
                        register={props.register}
                        hintMargins={'mt-1 mb-0'}
                        colorScheme={props.themeColor ? props.themeColor : 0}
                        required={true}
                    />
                    <StyledInput
                        title={'Номер телефона'}
                        // hint={<>Например: <span className='italic'>Иванов Иван Иванович</span></>}
                        name={'phone'}
                        // defaultValue={user.name}
                        error={props.errors.phone}
                        register={props.register}
                        // inputComponent={PhoneMask}
                        hintMargins={'mt-1 mb-0'}
                        colorScheme={props.themeColor ? props.themeColor : 0}
                        required={true}
                    />
                    <StyledInput
                        title={'E-mail'}
                        // hint={<>Например: <span className='italic'>Иванов Иван Иванович</span></>}
                        name={'email'}
                        // defaultValue={user.name}
                        error={props.errors.email}
                        register={props.register}
                        // inputComponent={PhoneMask}
                        hintMargins={'mt-1 mb-4'}
                        colorScheme={props.themeColor ? props.themeColor : 0}
                        required={false}
                    />
                    <LoadingButton
                        style={{backgroundColor:getThemeColors(props.themeColor ? props.themeColor : 0)}}
                        className={'w-full py-3 text-sm laptop:text-base text-white font-normal shadow-none hover:shadow-none normal-case rounded-14 cursor-pointer'}
                        loading={props.onLoading}
                        variant="contained"
                        type="submit"
                    >
                        Оформить заказ
                    </LoadingButton>
                    <div className='px-1 laptop:px-0 text-center laptop:text-left text-xs laptop:text-sm text-gray-14'>
                        Нажимая кнопку "Оформить заказ" я принимаю условия <NextLink href={'/offer'}>пользовательского соглашения</NextLink> и <NextLink href={'/privacy'}>политики конфиденциальности</NextLink>
                    </div>
                </div>
            </form>
        )
    }

    return(
        <>
            {screenPhone ? (
                <React.Fragment key={props.anchor}>
                    <SwipeableDrawer
                        anchor={props.anchor}
                        open={props.isOpen}
                        onClose={props.onClose}
                        onOpen={props.onOpen}
                    >
                        <OrderForm/>
                    </SwipeableDrawer>
                </React.Fragment>
            ) : (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={props.isOpen}
                    onClose={props.onClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: 'rgba(0, 0, 0, 0.43)'
                            },
                            timeout: screenPhone ? 150 : 500
                        },
                    }}
                >
                    <Fade in={props.isOpen}>
                        <Box sx={{ ...modalBoxStyle}} className='w-full tablet:w-auto tablet:max-w-xl h-screen tablet:h-auto bg-white border-0 outline-0 rounded-0 tablet:rounded-3xl'>
                            <OrderForm/>
                        </Box>
                    </Fade>
                </Modal>
            )}
        </>
    )
}

export {ModalRequestCall, ModalCreateCategory, ModalPayments, ModalOrder}