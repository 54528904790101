import * as React from "react";
import { useSnackbar } from 'notistack';
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useRouter} from 'next/router'
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from '@mui/material/CircularProgress';
import Header from "@/components/showcase/Header";
import {BackButton, StyledInput} from "@/components/showcase/_controls";
import {axiosTemplate} from "../../../lib/fetchJson";
import useUser from "../../../lib/useUser";
import useMediaQuery from "@mui/material/useMediaQuery";

async function checkCat(data, query, token){
    let filter = `sort[0]=id:desc&filters[userId][$eq]=${data.userId}&filters[name][$eqi]=${data.name}`
    if(data.parent){
        filter += `&filters[parent][id][$eq]=${data.parent}`
    }else{
        filter += `&filters[parent][id][$null]=true`
    }
    if(query.id){
        filter += `&filters[id][$ne]=${query.id}`
    }
    return await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/categories?${filter}`, "GET", null, true, token)
}

async function getCat(id, token){
    return await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/categories/${id}`, "GET", null, true, token)
}

async function putCat(id, data, token){
    return await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/categories/${id}`, "PUT", data, true, token)
}

async function postCat(data, token){
    return await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/categories`, "POST", data, true, token)
}

export default function CategoryCreate({ parentCat, modal, onSetCat }){
    const screenPhone = useMediaQuery('(max-width:470px)');
    const { enqueueSnackbar } = useSnackbar();
    let { push, asPath, pathname, query } = useRouter();
    const { user } = useUser({
        redirectTo: "/",
        redirectIfFound: false,
    })
    const { control, handleSubmit, setError, clearErrors, register, reset, formState: { errors } } = useForm();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [preLoading, setPreLoading] = useState(true);
    const [cat, setCat] = useState(null);
    const [parent, setParent] = useState(null);
    //inputs
    const [inputTextLength, setInputTextLength] = React.useState({
        name: 0
    })
    const handleInputTextLength = e => {
        const {name, value} = e.target;
        setInputTextLength({...inputTextLength,[name]:value.length});
    }

    if(parentCat){
        query = { parent: parentCat }
    }
 
    useEffect(() => {
        async function fetch() {
            if(user.isLoggedIn){
                if(query.id && !modal){
                    const temp = await getCat(query.id, user.token)
                    if(temp.data){
                        setCat(temp.data)
                        setInputTextLength({name: temp.data.data?.attributes?.name.length});
                    }
                }
                if(query.parent){
                    const temp = await getCat(query.parent, user.token)
                    if(temp.data){
                        setParent(temp.data)
                    }
                }
                setPreLoading(false)
            }else{
                setPreLoading(false)
            }
        }
        if(user){
            fetch()
        }
    }, [user])


    const onError = (errors, e) => {
        // console.log(errors, e);
        clearErrors()
    }
    async function onSubmit(data){
        setSubmitLoading(true);
        let dataIsValid = true
        if(!data.name || data.name.replace(/\s/g, "").length === 0){
            setError('name', { type: 'custom', message: 'Название категории не указано!' })
            dataIsValid = false
        }else{
            data.name = data.name.trim();
        }
        if(query.parent){
            data.parent = query.parent
        }
        data.userId = user.id
        const checkCatData = await checkCat(data, query, user.token)
        if(checkCatData.data?.data && checkCatData.data?.data.length > 0){
            setError('name', { type: 'custom', message: 'Категория уже существует!' })
            dataIsValid = false
        }
        if(dataIsValid){
            let post
            if(query.id && !modal){
                post = await putCat(query.id, {data}, user.token)
            }else{
                post = await postCat({data}, user.token)
            }
            if(post.data){
                if(query.id){
                    enqueueSnackbar(`Категория "${data.name}" отредактирована`, { variant: "success" });
                }else{
                    enqueueSnackbar(`Категория "${data.name}" добавлена`, { variant: "success" });
                }
                if(modal){
                    onSetCat(parentCat ? 2 : 1, post.data.data)
                }else{
                    await push({
                        pathname: "/showcase/category-list",
                        //query: { data: "test" },
                    });
                }
            }
            if(post.error){
                enqueueSnackbar(`${post.error}`, { variant: "error" });
                // console.log(post.error)
            }
        }
        setSubmitLoading(false);
    }

    if (preLoading){
        return(
            <div className="w-full flex flex-col h-screen items-center justify-center">
                <CircularProgress color="secondary" />
            </div>
        )
    }

    if(!user){
        return <></>
    }

    return(
        <>
            <div className={modal ? 'px-3 tablet:px-6 pt-6 tablet:py-6' : 'flex flex-col w-full tablet:w-96 laptop:w-130 px-3 tablet:px-0 pb-12'}>
                {modal ? (
                    <div className='flex flex-col items-center'>
                        {/*<img src='/assets/showcase/images/settings-categories-ph.svg' className='w-24 mt-4 mb-6' alt='EMPTY'/>*/}
                        <div className="mb-10 text-center font-black text-xl tablet:text-3xl">
                            {query && query.parent ? parent?.data?.attributes?.name ? 'Категория "' + parent?.data?.attributes?.name +'"' : 'Добавить подкатегорию' : cat?.data?.attributes ? 'Изменить категорию' : 'Новая категория'}
                        </div>
                    </div>
                ) : (
                    <>
                        {!screenPhone && (
                            <BackButton />
                        )}
                        <div className="mt-0 tablet:mt-10 mb-8 tablet:mb-10 text-center font-black text-xl tablet:text-3xl laptop:text-40px">
                            {query && query.parent ? parent?.data?.attributes?.name ? 'Категория "' + parent?.data?.attributes?.name +'"' : 'Добавить подкатегорию' : cat?.data?.attributes ? 'Изменить категорию' : 'Новая категория'}
                        </div>
                    </>
                )}
                <form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
                    <StyledInput
                        title={query && query.parent ? 'Название подкатегории' : 'Название категории'}
                        hint={query && query.parent ? <></> : <>Например: <span className='italic'>"Женская обувь"</span></>} //<>Например: <span className='italic'>"Кроссовки"</span></>
                        name="name"
                        defaultValue={cat?.data?.attributes?.name}
                        error={errors.name}
                        register={register}
                        required={true}
                        onInput={handleInputTextLength}
                        currentLength={inputTextLength.name}
                        maxLength={40}
                    />
                    <LoadingButton
                        loading={submitLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        className='mt-1 py-3.5 laptop:py-3 bg-green2 hover:bg-green3 text-sm laptop:text-base font-normal text-white bg-gradient-to-r from-pink-1 to-purple-1 hover:brightness-90 rounded-12 shadow-none hover:shadow-none normal-case'
                    >
                        Сохранить
                    </LoadingButton>
                </form>
            </div>
        </>
    )
}